@font-face {
    font-family: 'FamiliarFont';
    src: url('./AutumnFlowers-9YVZK.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

/*
@font-face {
    font-family: 'BodyFont';
    src: url('./SellenaBrush-x3JyK.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
*/

/*
@font-face {
    font-family: 'BodyFont';
    src: url('./DhelFyn-rvv0A.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
*/

@font-face {
    font-family: 'BodyFont';
    src: url('./JosefinSlab-Italic-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}